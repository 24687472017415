import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NAVBAR_TITLE } from 'src/utils/constants/global-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  navbarTitle = NAVBAR_TITLE;
  subscriptions: Subscription[] = [];
  userName!: string;
  isLoggedIn: boolean = false;

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
  constructor(private authService: AuthService) {}
  ngOnInit() {
    const userNameSubscription = this.authService.userName$.subscribe(
      name => (this.userName = name),
    );
    const loginStatusSubscription = this.authService.isUserLoggedIn$.subscribe(
      loginStatus => (this.isLoggedIn = loginStatus),
    );
    this.subscriptions.push(userNameSubscription, loginStatusSubscription);
  }

  backToOracleSystem(): void {
    window.location.href = 'http://pab-erp12-apps.toray.com.my:8001/OA_HTML/OA.jsp?OAFunc=OANEWHOMEPAGE';
  }
}
