export const endPoints = {
    ip: 'einv-tgm-pab.com',
    port: ':3350',
    contextPath: '/api/1.1',
  };
  
  export const environment = {
    production: false,
    baseUrl: `https://${endPoints.ip}${endPoints.contextPath}`,
  };
  